import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, TextField, Button, Divider, Link, Box } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { loader } from 'graphql.macro';
import ButtonWithCircularProgress from 'components/ButtonWithCircularProgress';

const RequestResetPasswordMutation = loader('graphql/mutations/RequestResetPassword.graphql');

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: {
        width: 400
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const PasswordRecovery = () => {
    const classes = useStyles();
    const history = useHistory();
    const [submit, setSubmit] = useState(false);
    const [email, setEmail] = useState("");
    const [requestResetPassword, { loading, error }] = useMutation(RequestResetPasswordMutation, {
        onCompleted: () => {
            history.push('/password/emailSent');
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        requestResetPassword({ variables: { input: { email: email } } });
    };

    const hasErrorEmail = () => {
        return submit && error && (
            error.message.includes('not found')
        );
    };

    const getErrorEmail = () => {
        if (!submit) return null;
        if (error && error.message.includes('not found')) return 'Cette adresse mail n\'est pas enregistrée.';
        return null;
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Réinitialiser</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField fullWidth label="Adresse mail" name="email" variant="standard"
                            value={email} onChange={e => { setSubmit(false); setEmail(e.target.value); }}
                            error={hasErrorEmail()} helperText={getErrorEmail()} />
                        <Box marginY={2}>
                            <ButtonWithCircularProgress disabled={email === "" || loading} type="submit" loading={loading} label="Réinitiliser" fullWidth />
                        </Box>
                    </form>
                    <Link align="right" color="secondary" component={RouterLink} to="/" underline="always" variant="body2">
                        {'Se connecter?'}
                    </Link>
                </CardContent>
            </Card>
        </div>
    );
};

export default PasswordRecovery;