import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import LoggedApp from 'scenes/LoggedApp';
import UnloggedApp from 'scenes/UnloggedApp';

const IsLoggedInQuery = loader('graphql/locals/IsLoggedIn.graphql');

const App = () => {
    const { data } = useQuery(IsLoggedInQuery, { fetchPolicy: 'cache-only' });

    return data.isLoggedIn ? <LoggedApp /> : <UnloggedApp />;
}

App.whyDidYouRender = true;

export default App;
