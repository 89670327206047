import React from 'react';
import { Card, Typography, Collapse, Box, FormControlLabel, Switch, TextField, Checkbox } from '@material-ui/core';
import MeetingDateForm from './MeetingDateForm';
import KmFees from './KmFees';
import * as R from 'ramda';
import { DateTime } from 'luxon';

const MeetingForm = ({ requestMeeting, meetingResponses, setMeetingResponses, indexMeeting, meetingMemberRoute }) => {
    const { meeting } = requestMeeting;
    const meetingResponse = meetingResponses[indexMeeting];

    const handleChange = (name) => (e) => {
        const lens = R.lensPath([indexMeeting, name]);
        setMeetingResponses(R.set(lens, e.target.value, meetingResponses));
    }

    const handleChangeChecked = (name) => (e) => {
        const lens = R.lensPath([indexMeeting, name]);
        setMeetingResponses(R.set(lens, e.target.checked, meetingResponses));
    }

    return (
        <Box marginBottom={3}>
            <Card>
                <Box padding={2}>
                    <Typography variant="h6">
                        {meeting.title}
                    </Typography>
                    <Typography variant="body1">
                        {`Du ${DateTime.fromSQL(meeting.startDate).toLocaleString(DateTime.DATE_MED)} au ${DateTime.fromSQL(meeting.endDate).toLocaleString(DateTime.DATE_MED)}`}
                    </Typography>
                    <Typography variant="body1">
                        {`${meeting.address} ${meeting.zipCode} ${meeting.city}`}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={meetingResponse.accepted}
                                onChange={handleChangeChecked('accepted')}
                                color="primary"
                            />
                        }
                        label="Je suis disponible pour participer"
                    />
                    <Collapse in={meetingResponse.accepted} timeout="auto">
                        <KmFees league={meeting.league} meetingResponse={meetingResponse} meetingMemberRoute={meetingMemberRoute} />
                        <TextField fullWidth label="Informations" value={meetingResponse.informations} onChange={handleChange('informations')} />
                        <FormControlLabel
                            control={
                                <Checkbox checked={meetingResponse.eveHotel} disabled={!meeting.eveHotel}
                                    onChange={handleChangeChecked('eveHotel')} />
                            }
                            label="Hotel la veille"
                        />
                        {meeting.meetingDates.map((meetingDate, indexMeetingDate) => <MeetingDateForm key={meetingDate.id} meetingDate={meetingDate}
                            meetingResponses={meetingResponses} setMeetingResponses={setMeetingResponses} indexMeeting={indexMeeting} indexMeetingDate={indexMeetingDate} />)}
                    </Collapse>
                </Box>
            </Card>
        </Box>
    );
};

MeetingForm.whyDidYouRender = true

export default MeetingForm;