import React from 'react';
import { FormControlLabel, Switch, Checkbox, Collapse, TextField, Box } from '@material-ui/core';
import * as R from 'ramda';
import { DateTime } from 'luxon';

const MeetingDateForm = ({ meetingDate, meetingResponses, setMeetingResponses, indexMeeting, indexMeetingDate }) => {
    const meetingDateResponse = meetingResponses[indexMeeting].meetingDates[indexMeetingDate];

    const handleChangeChecked = (name) => (e) => {
        const lens = R.lensPath([indexMeeting, 'meetingDates', indexMeetingDate, name]);
        setMeetingResponses(R.set(lens, e.target.checked, meetingResponses));
    }

    const handleChange = (name) => (e) => {
        const lens = R.lensPath([indexMeeting, 'meetingDates', indexMeetingDate, name]);
        setMeetingResponses(R.set(lens, e.target.value, meetingResponses));
    }

    return (
        <Box marginBottom={3}>
            <FormControlLabel
                control={
                    <Switch
                        checked={meetingDateResponse.accepted}
                        onChange={handleChangeChecked('accepted')}
                        color="primary"
                    />
                }
                label={DateTime.fromSQL(meetingDate.date).toLocaleString(DateTime.DATE_MED)}
            />
            <Collapse in={meetingDateResponse.accepted} timeout="auto" unmountOnExit>
                <FormControlLabel
                    control={
                        <Checkbox checked={meetingDateResponse.lunch} disabled={!meetingDate.lunch}
                            onChange={handleChangeChecked('lunch')} />
                    }
                    label="Repas midi"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={meetingDateResponse.dinner} disabled={!meetingDate.dinner}
                            onChange={handleChangeChecked('dinner')} />
                    }
                    label="Repas soir"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={meetingDateResponse.hotel} disabled={!meetingDate.hotel}
                            onChange={handleChangeChecked('hotel')} />
                    }
                    label="Hotel"
                />
                <TextField fullWidth label="Informations pour ce jour uniquement" value={meetingDateResponse.informations} onChange={handleChange('informations')} />
            </Collapse>
        </Box>
    );
};

MeetingDateForm.whyDidYouRender = true

export default MeetingDateForm;