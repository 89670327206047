import React, { useState, lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Switch, Route } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { UserContext } from 'context';
import Cookies from 'universal-cookie';
import { LinearProgress } from '@material-ui/core';
import TopBar from './components/TopBar';
import NavBar from './components/NavBar';
import Home from './scenes/Home';
import Account from './scenes/Account';
import Logout from './scenes/Logout';

const Matches = lazy(() => import('./scenes/Matches'));
const Referees = lazy(() => import('./scenes/Referees'));
const Trainings = lazy(() => import('./scenes/Trainings'));
const Trainers = lazy(() => import('./scenes/Trainers'));
const Meetings = lazy(() => import('./scenes/Meetings'));
const Members = lazy(() => import('./scenes/Members'));
const Admins = lazy(() => import('./scenes/Admins'));
const Users = lazy(() => import('./scenes/Users'));
const LeagueSettings = lazy(() => import('./scenes/LeagueSettings'));

const cookies = new Cookies();

const LogoutMutation = loader('graphql/mutations/Logout.graphql');
const CurrentUserQuery = loader('graphql/queries/CurrentUser.graphql');

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto'
    },
    content: {
        overflowY: 'auto',
        flex: '1 1 auto'
    }
}));

const MainApp = () => {
    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
    const { data, error, loading } = useQuery(CurrentUserQuery);
    const [logout] = useMutation(LogoutMutation,
        {
            onCompleted: () => {
                window.location.reload();
            },
            onError: () => {
                if (process.env.NODE_ENV === 'production') {
                    cookies.remove('id', { httpOnly: false, domain: process.env.REACT_APP_COOKIES_DOMAIN });
                }
                if (process.env.NODE_ENV === 'development') {
                    cookies.remove('id', { httpOnly: false });
                }
                window.location.reload();
            }
        });

    if (loading)
        return (
            <div className={classes.root}>
                <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} user={null} />
                <LinearProgress />
            </div>
        );

    if (error) {
        logout();
        return `Error! ${error.message}`;
    }

    const { user } = data;

    return (
        <UserContext.Provider value={user}>
            <div className={classes.root}>
                <TopBar user={user} onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
                <div className={classes.container}>
                    <NavBar user={user} onMobileClose={() => setOpenNavBarMobile(false)} openMobile={openNavBarMobile} />
                    <div className={classes.content}>
                        <Suspense fallback={<LinearProgress />}>
                            <Switch>
                                <Route exact path='/'>
                                    <Home />
                                </Route>
                                <Route exact path='/logout'>
                                    <Logout />
                                </Route>
                                <Route exact path='/account'>
                                    <Account />
                                </Route>
                                {(user.admin || user.permissions.includes('match_read')) &&
                                    <Route path='/matches'>
                                        <Matches />
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('match_read')) &&
                                    <Route path='/referees'>
                                        <Referees />
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('training_read')) &&
                                    <Route path='/trainings'>
                                        <Trainings />
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('training_read')) &&
                                    <Route path='/trainers'>
                                        <Trainers />
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('meeting_read')) &&
                                    <Route path='/meetings'>
                                        <Meetings />
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('meeting_read')) &&
                                    <Route path='/members'>
                                        <Members />
                                    </Route>
                                }
                                {user.admin && !user.department &&
                                    <Route exact path='/league'>
                                        <LeagueSettings />
                                    </Route>
                                }
                                {user.admin && !user.department &&
                                    <Route path='/admins'>
                                        <Admins />
                                    </Route>
                                }
                                {user.admin &&
                                    <Route path='/users'>
                                        <Users />
                                    </Route>
                                }
                            </Switch>
                        </Suspense>
                    </div>
                </div>
            </div>
        </UserContext.Provider>
    );
};

export default MainApp;