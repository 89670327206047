import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './scenes/Home';
import Password from './scenes/Password';
import Invitation from './scenes/Invitation';
import InviteUser from 'scenes/UnloggedApp/scenes/InviteUser';

const UnloggedApp = () => {
    return (
        <Switch>
            <Route exact path='/'>
                <Home />
            </Route>
            <Route path='/invite/user/:token'>
                <InviteUser />
            </Route>
            <Route path='/password'>
                <Password />
            </Route>
            <Route path='/invitation'>
                <Invitation />
            </Route>
            <Route path='/'>
                <Home />
            </Route>
        </Switch>
    );
};

export default UnloggedApp;