import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { UserContext } from 'context';
import {
    Container, Typography, Box, Card,
    TextField
} from '@material-ui/core';
import ButtonWithCircularProgress from 'components/ButtonWithCircularProgress';

const UpdateEmailMutation = loader('graphql/mutations/UpdateEmail.graphql');

const Account = () => {
    const user = useContext(UserContext);
    const [email, setEmail] = useState(user.email);
    const [updateEmail, { loading }] = useMutation(UpdateEmailMutation);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateEmail({ variables: { input: { email: email } } });
    };

    return (
        <Container maxWidth="md">
            <Box marginBottom={4}>
                <Box marginY={4}>
                    <Box marginBottom={2}>
                        <Typography variant="h5" align="center">Mon compte</Typography>
                    </Box>
                </Box>
                <Card variant="outlined" square>
                    <Box padding={2} display="flex" flexDirection="column" alignItems="center">
                        <TextField label="Adresse mail" name="email" onChange={(e) => setEmail(e.target.value)} required value={email} />
                        <Box marginTop={2}>
                            <ButtonWithCircularProgress loading={loading} label="Mettre à jour" disabled={email === "" || loading} onClick={handleSubmit} />
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Container>
    );
};

export default Account;