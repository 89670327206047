import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router';
import { loader } from 'graphql.macro';
import Cookies from 'universal-cookie';
import { Container, Box, Typography, LinearProgress } from '@material-ui/core';

const cookies = new Cookies();
const LogoutMutation = loader('graphql/mutations/Logout.graphql');

const Logout = () => {
    const history = useHistory();
    const [logout] = useMutation(LogoutMutation,
        {
            onCompleted: () => {
                window.location.reload();
            },
            onError: () => {
                cookies.remove('id', { httpOnly: false, domain: process.env.REACT_APP_COOKIES_DOMAIN });
                history.push('/');
                window.location.reload();
            }
        });

    useEffect(() => {
        logout();
    }, [logout]);

    return (
        <Container>
            <Box marginTop={4} marginBottom={3}>
                <Typography variant="h3">Déconnexion en cours</Typography>
            </Box>
            <LinearProgress />
        </Container>
    );
};

export default Logout;