import React from 'react';
import { FormControlLabel, Switch, Checkbox, Collapse, TextField, Box } from '@material-ui/core';
import * as R from 'ramda';
import { DateTime } from 'luxon';

const MatchDateForm = ({ matchDate, matchResponses, setMatchResponses, indexMatch, indexMatchDate }) => {
    const matchDateResponse = matchResponses[indexMatch].matchDates[indexMatchDate];

    const handleChangeChecked = (name) => (e) => {
        const lens = R.lensPath([indexMatch, 'matchDates', indexMatchDate, name]);
        setMatchResponses(R.set(lens, e.target.checked, matchResponses));
    }

    const handleChange = (name) => (e) => {
        const lens = R.lensPath([indexMatch, 'matchDates', indexMatchDate, name]);
        setMatchResponses(R.set(lens, e.target.value, matchResponses));
    }

    return (
        <Box marginBottom={3}>
            <FormControlLabel
                control={
                    <Switch
                        checked={matchDateResponse.accepted}
                        onChange={handleChangeChecked('accepted')}
                        color="primary"
                    />
                }
                label={DateTime.fromSQL(matchDate.date).toLocaleString(DateTime.DATE_MED)}
            />
            <Collapse in={matchDateResponse.accepted} timeout="auto" unmountOnExit>
                <FormControlLabel
                    control={
                        <Checkbox checked={matchDateResponse.lunch} disabled={!matchDate.lunch}
                            onChange={handleChangeChecked('lunch')} />
                    }
                    label="Repas midi"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={matchDateResponse.dinner} disabled={!matchDate.dinner}
                            onChange={handleChangeChecked('dinner')} />
                    }
                    label="Repas soir"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={matchDateResponse.hotel} disabled={!matchDate.hotel}
                            onChange={handleChangeChecked('hotel')} />
                    }
                    label="Hotel"
                />
                <TextField fullWidth label="Informations pour ce jour uniquement" value={matchDateResponse.informations} onChange={handleChange('informations')} />
            </Collapse>
        </Box>
    );
};

MatchDateForm.whyDidYouRender = true

export default MatchDateForm;