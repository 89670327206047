import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: props => props.size === "medium" ? -12 : -13,
        marginLeft: props => props.size === "medium" ? -12 : -13,
        width: props => props.fullWidth ? "100%" : "auto"
    }
}));

const ButtonWithCircularProgress = ({ variant = "contained", color = "secondary", disabled, loading, icon: Icon, onClick, label, type = "button", size = "medium", fullWidth = false }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button variant={variant} disabled={disabled} color={color} startIcon={Icon && <Icon />}
                onClick={onClick} type={type} size={size} fullWidth={fullWidth}>
                {label}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
};

export default ButtonWithCircularProgress;