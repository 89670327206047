import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Match from './scenes/Match';
import Training from './scenes/Training';
import Meeting from './scenes/Meeting';

const Invitation = () => {
    return (
        <Switch>
            <Route exact path='/invitation/match/:token'>
                <Match />
            </Route>
            <Route exact path='/invitation/training/:token'>
                <Training />
            </Route>
            <Route exact path='/invitation/meeting/:token'>
                <Meeting />
            </Route>
        </Switch>
    );
};

export default Invitation;