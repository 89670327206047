import React from 'react';
import { Card, Typography, Collapse, Box, FormControlLabel, Switch, TextField, Checkbox } from '@material-ui/core';
import TrainingDateForm from './TrainingDateForm';
import KmFees from './KmFees';
import * as R from 'ramda';
import { DateTime } from 'luxon';

const TrainingForm = ({ requestTraining, trainingResponses, setTrainingResponses, indexTraining, trainingTrainerRoute }) => {
    const { training } = requestTraining;
    const trainingResponse = trainingResponses[indexTraining];

    const handleChange = (name) => (e) => {
        const lens = R.lensPath([indexTraining, name]);
        setTrainingResponses(R.set(lens, e.target.value, trainingResponses));
    }

    const handleChangeChecked = (name) => (e) => {
        const lens = R.lensPath([indexTraining, name]);
        setTrainingResponses(R.set(lens, e.target.checked, trainingResponses));
    }

    return (
        <Box marginBottom={3}>
            <Card>
                <Box padding={2}>
                    <Typography variant="h6">
                        {training.title}
                    </Typography>
                    <Typography variant="body1">
                        {`Du ${DateTime.fromSQL(training.startDate).toLocaleString(DateTime.DATE_MED)} au ${DateTime.fromSQL(training.endDate).toLocaleString(DateTime.DATE_MED)}`}
                    </Typography>
                    <Typography variant="body1">
                        {`${training.address} ${training.zipCode} ${training.city}`}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={trainingResponse.accepted}
                                onChange={handleChangeChecked('accepted')}
                                color="primary"
                            />
                        }
                        label="Je suis disponible pour participer"
                    />
                    <Collapse in={trainingResponse.accepted} timeout="auto">
                        <KmFees league={training.league} trainingResponse={trainingResponse} trainingTrainerRoute={trainingTrainerRoute} />
                        <TextField fullWidth label="Informations" value={trainingResponse.informations} onChange={handleChange('informations')} />
                        <FormControlLabel
                            control={
                                <Checkbox checked={trainingResponse.eveHotel} disabled={!training.eveHotel}
                                    onChange={handleChangeChecked('eveHotel')} />
                            }
                            label="Hotel la veille"
                        />
                        {training.trainingDates.map((trainingDate, indexTrainingDate) => <TrainingDateForm key={trainingDate.id} trainingDate={trainingDate}
                            trainingResponses={trainingResponses} setTrainingResponses={setTrainingResponses} indexTraining={indexTraining} indexTrainingDate={indexTrainingDate} />)}
                    </Collapse>
                </Box>
            </Card>
        </Box>
    );
};

TrainingForm.whyDidYouRender = true

export default TrainingForm;