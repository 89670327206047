import React from 'react';
import { Typography } from '@material-ui/core';

const KmFees = ({ league, meetingResponse, meetingMemberRoute }) => {

    let kmFees = 0;
    const pricePerKm = league.pricePerKm / 1000;

    if (meetingResponse.accepted && meetingResponse.meetingDates.filter(d => d.accepted).length !== 0) {
        let atHome = true;

        if (meetingResponse.eveHotel) {
            kmFees += pricePerKm * meetingMemberRoute.to;
            atHome = false;
        }

        let eveHotel = false
        meetingResponse.meetingDates.forEach(meetingDate => {
            if (meetingDate.accepted) {
                if (atHome) {
                    kmFees += pricePerKm * meetingMemberRoute.to;
                    atHome = false;
                }

                if (!meetingDate.hotel) {
                    atHome = true;
                    kmFees += pricePerKm * meetingMemberRoute.from;
                } else {
                    eveHotel = true;
                }
            } else {
                if (!atHome && eveHotel) {
                    eveHotel = false;
                    atHome = true;
                    kmFees += pricePerKm * meetingMemberRoute.from;
                }
            }
        });

        if (!atHome)
            kmFees += pricePerKm * meetingMemberRoute.from;
    }

    return (
        <Typography variant="body1">
            {`Estimation frais kilométrique: ${kmFees.toFixed(2)}€`}
        </Typography>
    );
};

export default KmFees;