import {  createMuiTheme } from '@material-ui/core';

import overrides from './overrides';
import { teal, amber } from '@material-ui/core/colors';

const theme = createMuiTheme({
    overrides,
    palette: {
        primary: teal,
        secondary: amber
    }
});

export default theme;