import React from 'react';
import { Card, Typography, Collapse, Box, FormControlLabel, Switch, TextField, Checkbox } from '@material-ui/core';
import MatchDateForm from './MatchDateForm';
import KmFees from './KmFees';
import * as R from 'ramda';
import { DateTime } from 'luxon';

const MatchForm = ({ requestMatch, matchResponses, setMatchResponses, indexMatch, matchRefereeRoute }) => {
    const { match } = requestMatch;
    const matchResponse = matchResponses[indexMatch];

    const handleChange = (name) => (e) => {
        const lens = R.lensPath([indexMatch, name]);
        setMatchResponses(R.set(lens, e.target.value, matchResponses));
    }

    const handleChangeChecked = (name) => (e) => {
        const lens = R.lensPath([indexMatch, name]);
        setMatchResponses(R.set(lens, e.target.checked, matchResponses));
    }

    return (
        <Box marginBottom={3}>
            <Card>
                <Box padding={2}>
                    <Typography variant="h6">
                        {match.title}
                    </Typography>
                    <Typography variant="body1">
                        {`Du ${DateTime.fromSQL(match.startDate).toLocaleString(DateTime.DATE_MED)} au ${DateTime.fromSQL(match.endDate).toLocaleString(DateTime.DATE_MED)}`}
                    </Typography>
                    <Typography variant="body1">
                        {`${match.address} ${match.zipCode} ${match.city}`}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={matchResponse.accepted}
                                onChange={handleChangeChecked('accepted')}
                                color="primary"
                            />
                        }
                        label="Je suis disponible pour participer"
                    />
                    <Collapse in={matchResponse.accepted} timeout="auto">
                        <KmFees league={match.league} matchResponse={matchResponse} matchRefereeRoute={matchRefereeRoute} />
                        <TextField fullWidth label="Informations" value={matchResponse.informations} onChange={handleChange('informations')} />
                        <FormControlLabel
                            control={
                                <Checkbox checked={matchResponse.eveHotel} disabled={!match.eveHotel}
                                    onChange={handleChangeChecked('eveHotel')} />
                            }
                            label="Hotel la veille"
                        />
                        {match.matchDates.map((matchDate, indexMatchDate) => <MatchDateForm key={matchDate.id} matchDate={matchDate}
                            matchResponses={matchResponses} setMatchResponses={setMatchResponses} indexMatch={indexMatch} indexMatchDate={indexMatchDate} />)}
                    </Collapse>
                </Box>
            </Card>
        </Box>
    );
};

MatchForm.whyDidYouRender = true

export default MatchForm;